import React from 'react';
import HomeFlexCenterBackground from '../repeatedComponent/homeflexcenter';
import { useNavigate } from 'react-router-dom';


export default function NotFound(props) {
    const usenavigate = useNavigate();
    return(
        <HomeFlexCenterBackground>
            <div className='flex justify-center items-center flex-col'>
                <h1 className='font-bold md:text-8xl text-6xl'>
                    4😽4
                </h1>
                <h2>
                Page Not Found
                </h2>
                <button className='p-3 m-3 border border-2 border-black rounded-md' onClick={()=> usenavigate('/')}>Click here to redirect to main page</button>
            </div>
        </HomeFlexCenterBackground>
    )
}