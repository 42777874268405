import React, { useEffect } from "react";
import Program from "./LandingPageComponent/programs";
import Footer from "./LandingPageComponent/footer";
import WhyUs from "./LandingPageComponent/whyUs";
import { useNavigate } from "react-router-dom";
import BecameTeacher from "./LandingPageComponent/becameTeacher";

export default function Landingpage(props) {
  const nevigate = useNavigate();
  const scrollToSection = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    nevigate("/login");
  }, []);

  return (
    <div className="overflow-x-hidden ubuntu_font">
      <div className="h-screen max-h-fit w-screen landingpage flex-col">
        <nav className="flex justify-between items-center p-1 md:px-16">
          <h1 className="m-1 text-yellow-500 text-lg md:text-xl  protest_riot">
            pixelPirates.in
          </h1>
          {/* CodeKatha on hold */}
          <ul className="flex text-white ubuntu_font font-bold items-center justify-around">
            <li
              onClick={() => scrollToSection("whyUS")}
              className="md:mx-2 text-md md:px-2 px-1 cursor-pointer"
            >
              WhyUS
            </li>
            {/* <li className='md:mx-2 text-md md:px-2 px-1'>About Us</li> */}
            <li
              onClick={() => scrollToSection("program")}
              className="md:mx-2 text-md md:px-2 px-1"
            >
              Programs
            </li>
          </ul>
          <button
            onClick={() => nevigate("/login")}
            className="md:p-2 p-1 px-1 md:px-10 bg-yellow-500 rounded-lg shadow-2xl shadow-black bg-gradient-to-r from-yellow-300 to-yellow-500 border-2"
          >
            LogIn
          </button>
        </nav>
        <div className="h-95 flex flex-col justify-around md:flex-row md:p-16 p-5 pt-10">
          <div className="md:w-1/2 w-100 flex flex-col justify-center items-center">
            <h1 className="text-2xl md:text-6xl font-bold protest_revolution text-white">
              Decode your future. Become a{" "}
              <span className="text-yellow-600">coding architect.</span>
            </h1>
            <h2 className="ubuntu_font font-bold text-white my-10">
              Craving coding? Satisfy your knowledge hunger. Come, feast on code
              mastery, transform your learning appetite into a delightful coding
              journey!
            </h2>
            <div>
              <button
                onClick={() => nevigate("/exploreMenu")}
                className="bg-gradient-to-r from-yellow-300 to-yellow-500 p-2 rounded-lg shadow-2xl shadow-slate-600 flex justify-between items-center border-2 self-start"
              >
                Explore Menu
                <span className="material-symbols-outlined">
                  arrow_right_alt
                </span>
              </button>
            </div>
          </div>
          <div className="font-bold text-white flex flex-col items-center justify-center md:w-1/2 w-100 md:p-0 p-5">
            <div className="bg-yellow-500 shadow-2xl shadow-purple-800 rounded-t-3xl flex justify-center items-baseline mx-10 max-w-80">
              <div className="bg-white m-2 rounded-t-3xl p-2 flex justify-center items-center ">
                <img
                  className="h-100 w-100 bg-cover bg-center"
                  src="/personLandingCodingPage.jpeg"
                  alt="coding person"
                />
              </div>
            </div>
            <h1 className=" text-8xl protest_riot">&lt;&lt;&gt;&lt;|&lt;</h1>
          </div>
        </div>
      </div>
      <WhyUs />
      <Program />
      <BecameTeacher />
      <div className="w-screen p-5 md:p-10 bg-slate-200">
        <div className="bg-slate-200 border-2 border-white p-2 rounded-xl shadow-lg shadow-violet-400">
          <div className="bg-violet-600 rounded-xl  text-white p-10 flex justify-center items-center">
            <div className="md:w-1/2 flex flex-col justify-around items-center">
              <h1 className="protest_riot text-4xl my-10 text-center md:text-left">
                Get Started With{" "}
                <span className="text-yellow-400">pixelPirates</span>
              </h1>
              <p className="my-5 text-center">
                Our classes guarantee the acquisition of essential skills.
                Additionally, we conduct competitions throughout the course –
                the only program featuring live competitions for an immersive
                learning experience.
              </p>
              <button
                onClick={() => nevigate("/exploreMenu")}
                className="bg-gradient-to-r from-yellow-300 to-yellow-500 p-2 rounded-lg shadow-2xl shadow-slate-600 flex justify-between items-center border-2 text-black m-10"
              >
                Get Started
                <span className="material-symbols-outlined">
                  arrow_right_alt
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
