import React, { useState } from 'react';
import FrontEnd from './frontEnd';
import DSA from './Dsa';

export default function Program(props) {
    const [portion,setPortion] = useState(false);
    return(
        <div id='program' className=' bg-slate-200 w-screen p-10 flex flex-col justify-center items-center md:block'>
            <div className='flex justify-between items-center'>
                <h1 className='ubuntu_font font-bold text-xl md:text-2xl'><span className='text-yellow-500 bg-gradient-to-r from-violet-500 to-violet-800 p-2 rounded-lg text-2xl'>👑</span> Our Best Programs</h1>
                <div>
                    <button onClick={()=> setPortion(!portion)} className='rounded-full px-4 p-2 m-1 shadow-xl hover:shadow-lg'>&lt;</button>
                    <button onClick={()=> setPortion(!portion)} className='rounded-full px-4 p-2 m-1  shadow-xl hover:shadow-lg'>&gt;</button>
                </div>
            </div>
            <div className='relative bg-slate-200 border-2 md:m-auto border-white my-10 md:my-10 w-fit md:p-2 rounded-3xl shadow-2xl shadow-violet-600'>
                <div className='flex md:flex-row flex-col bg-gradient-to-r from-purple-500 to-purple-600 rounded-3xl'>
                    <div onClick={()=> setPortion(false)} className={`m-5 flex flex-col justify-center items-center text-white w-80 hover:bg-gradient-to-r hover:rounded-lg hover:from-yellow-400 hover:to-yellow-600 transition duration-500  py-5 px-4 ${portion ? '':'bg-gradient-to-r rounded-lg from-yellow-400 to-yellow-600'}`}>
                        <div className='bg-purple-700 w-max border p-3 border-white rounded-lg'>
                            <span className=" text-yellow-400 text-bold text-3xl  protest_riot">
                                &gt;_
                            </span>
                        </div>
                        <h1 className='font-bold'>Data Structure & Algorithms</h1>
                        <h2 className='text-center text-xs my-2'>Master in-demand Data Structures & Algorithms. Get job-ready with expert coaching.</h2>
                    </div>
                    <div onClick={()=> setPortion(true)} className={`m-5 flex flex-col justify-center items-center text-white w-80 hover:bg-gradient-to-r hover:rounded-lg hover:from-yellow-600 hover:to-yellow-400 transition duration-500 py-5 px-4 ${!portion ? '':'bg-gradient-to-r rounded-lg from-yellow-400 to-yellow-600'}`}>
                        <div className='bg-purple-700 w-max border p-3 border-white rounded-lg'>
                            <span className=" text-yellow-400 text-bold text-3xl  protest_riot">
                                &lt;/&gt;
                            </span>
                        </div>
                        <h1 className='font-bold'>Full Stack Development</h1>
                        <h2 className='text-center text-xs my-2'>Become a full-stack powerhouse. Master front-end, back-end, and more with expert coaching.</h2>
                    </div>
                </div>
            </div>
            {
                portion ?
                <div className='m-auto flex justify-center items-center'>
                    <FrontEnd/>
                </div>
                :
                <div className='m-auto flex justify-center items-center'>
                    <DSA/>
                </div>
            }
            
        </div>
    )
}