import _ from "lodash";
import data from "./CertificationMain.json";

export const getUser = (certificationId, navigate) => {
  const user = _.find(data, { id: certificationId });
  console.log(user);

  if (!user) {
    navigate("/-------------");
  }
  return user;
};
