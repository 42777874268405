import React from 'react';

export default function WhyUs(props) {
    return(
        <div id='whyUS' className='md:p-10 p-5 py-10 bg-slate-200'>
            <h1 className='font-bold text-xl md:text-2xl'><span className='text-yellow-500 bg-gradient-to-r from-violet-500 to-violet-800 p-2 rounded-lg text-3xl px-4'>♦️</span> Why Us</h1>
            <div className='flex flex-col-reverse sm:flex-row justify-center items-center md:p-10 mt-5'>
                <div className='font-bold text-white flex flex-col items-center justify-center md:w-1/2 my-5'>
                    <div className='bg-yellow-500 shadow-2xl shadow-purple-800 rounded-t-3xl flex justify-center items-baseline mx-10 max-w-80'>
                        <div className='bg-white m-2 rounded-t-3xl p-2 flex justify-center items-center '>
                            <img className='h-100 w-100 bg-cover bg-center' src="/typingComputer.jpeg" alt="coding person" />
                        </div>
                    </div>
                </div>
                <div className='md:w-1/2'>
                    <h1 className='font-bold text-2xl'>Our Value To discipline</h1>
                    <p className='my-5'>At the heart of our daily operations, prioritizing tasks takes precedence, and our secret sauce lies in the invigorating spirit of healthy competition, propelling us toward continuous improvement and excellence 🚀.</p>
                    <div className='grid grid-cols-2'>
                        <div className='m-1 bg-violet-200 p-5 rounded-md shadow-md border-2 border-white hover:-translate-x-1 hover:-translate-y-1 delay-100 hover:ease-in-out duration-300'>
                            <h1 className='font-bold'>Indiavidual Task</h1>
                            <p>Individual Task Assigned <br /> this tasks score added to your performance analysis.</p>
                        </div>
                        <div className='m-1 bg-violet-200 p-5 rounded-md shadow-md border-2 border-white hover:translate-x-1 hover:-translate-y-1 delay-100 hover:ease-in-out duration-300'>
                            <h1 className='font-bold'>Group Compition</h1>
                            <p>Students are divided in the groups and groups compete with other groups.</p>
                        </div>
                        <div className='m-1 bg-violet-200 p-5 rounded-md shadow-md border-2 border-white hover:-translate-x-1 hover:translate-y-1 delay-100 hover:ease-in-out duration-300'>
                            <h1 className='font-bold'>Interview preparation</h1>
                            <p>Interviews are sheduled at certain price with the company employes.</p>
                        </div>
                        <div className='m-1 bg-violet-200 p-5 rounded-md shadow-md border-2 border-white hover:translate-x-1 hover:translate-y-1 delay-100 hover:ease-in-out duration-300'>
                            <h1 className='font-bold'>Practical Skills</h1>
                            <p>By sheduling the tasks and the compition you will gain a basic knowledge abut the technologies.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}