import React from 'react';

export default function DSA(props) {
    return(
        <div className='flex flex-col  ubuntu_font'>
            <div className='md:mx-5 my-3 bg-violet-200 border-2 border-white p-5 shadow-xl rounded-xl  duration-700 hover:-translate-y-5 hover:ease-in-out delay-100'>
                <h1 className='font-bold m-2'>Programming In Java</h1>
                <ul className='flex flex-wrap'>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Flowcharts & Pseudocode</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Variables & Data Types</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Operators</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Conditional Statements</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Loops (Flow Control)</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Patterns</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Strings</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Functions & Methods</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Functions & Arrays</li>
                </ul>
            </div>
            <div className='md:mx-5 my-3 bg-violet-200 border-2 border-white p-5 shadow-xl rounded-xl duration-700 hover:-translate-y-5 hover:ease-in-out delay-100'>
                <h1 className='font-bold m-2'>Data Structure And Algorithms</h1>
                <ul className='flex flex-wrap'>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Sorting Algorithms</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Bit Manipulation</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Object Orientation (OOPs)</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Recursion & Backtracking</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Divide & Conquer</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>ArrayLists</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Linked Lists</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Stacks & Queues</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Binary Trees</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Binary Search Trees</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>HashMaps & HashSets</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Heaps (Priority Queue)</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Tries</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Graph</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Greedy Algorithms</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Dynamic Programming</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Segment Trees</li>
                </ul>
            </div>
            <div className='md:mx-5 my-3 bg-violet-200 border-2 border-white p-5 shadow-xl rounded-xl duration-700 hover:-translate-y-5 hover:ease-in-out delay-100'>
                <h1 className='font-bold mb-5'>Extra Benifits</h1>
                <ul className='flex flex-wrap'>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>End With High Class 3 Projects</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Resume Building</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>LinkedIn Profile Building</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Interview Prep</li>
                </ul>
            </div>
        </div>
    )
}
