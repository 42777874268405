import React from 'react';

export default function Footer(props) {
    const currentDate = new Date();
    return(
        <div className='w-screen  shadow-sm bg-slate-200 px-5 md:px-10'>
            <div className='rounded-t-3xl backdrop-blur-xl bg-gradient-to-r from-yellow-400 to-yellow-600 px-10 shadow-lg'>
                <div className='flex flex-col md:flex-row'>
                    <div className='pt-1 sm:py-10 sm:w-1/2'>
                        <h1 className='text-black protest_riot'>pixelPirates</h1>
                        <p className='ubuntu_font'>pixelPirates Comes under the Sodd.pvt.lmt</p>
                        <p>Mail at <a href="mailto:siddh6133@mail.com" className='font-bold'>siddh6133@mail.com</a></p>
                    </div>
                    <div className='flex md:w-1/2 md:p-10 justify-center items-center'>
                        <h1 className='m-3 font-bold'>AboutUs</h1>
                        <h1  className='m-3 font-bold'>ContactUs</h1>
                        <h1  className='m-3 font-bold'>Support</h1>
                    </div>
                </div>
                
                <div className='text-center flex flex-col md:flex-row justify-between items-center'>
                    <h1>@{currentDate.getFullYear()} pixelPirates All rights reserved.</h1>
                    <div className='flex flex-row'>
                        <h3>Terms & Agreements</h3>
                        <h3 className='ml-4'>Privacy Policy</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}