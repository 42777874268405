import { useReducer,useContext,createContext } from "react";



// step 1: initialize the dictionary elements
const initialState = {
    user:null,
    theme:'light',
    userStatus:'free'
}

// step 2: name the context with cretecontext
const AppContext = createContext();

// step 3: Create the reducer
const appReducer = (state,action) => {
    switch(action.type) {
        case 'set_user':
            return {...state,user:action.payload};
        case 'toggle_theme':
            return {...state,theme:state.theme === 'light' ? 'dark':'light'};
        default:
            return state;
    }
}

// step 4: create the context
const useAppContext = ()=> {
    const context = useContext(AppContext);
    if(!context){
        throw new Error('user AppContext must be used with in an AppProvider');
    }
    return context;
}

// step 5: create the component
const AppProvider = ({children}) => {
    const [state,dispatch] = useReducer(appReducer,initialState);

    return(
        <AppContext.Provider value={{state,dispatch}}>
            {children}
        </AppContext.Provider>
    )
}


export {AppProvider,useAppContext};