import React from 'react';

export default function FrontEnd(props) {
    return(
        <div className='flex flex-col  ubuntu_font'>
            <div className='md:mx-5 my-3 bg-violet-200 border-2 border-white p-5 shadow-lg rounded-xl duration-700 hover:-translate-y-5 hover:ease-in-out delay-100'>
                <h1 className='font-bold m-2'>Front End</h1>
                <ul className='flex flex-wrap'>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>HTML & JSX</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Inline v/s Block Elements</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Tables & Forms</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Intro to CSS</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Selectors in CSS</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Box Model & CSS Units</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Flexbox & Responsiveness</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Bootstrap</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Tailwind CSS</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>JavaScript Basics</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Functions & Arrays</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Objects & Timing Functions</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>DOM Manipulation</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Promises & Callback</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Git & Github</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>React</li>
                </ul>
            </div>
            <div className='md:mx-5 my-3 bg-violet-200 border-2 border-white p-5 shadow-lg rounded-xl duration-700 hover:-translate-y-5 hover:ease-in-out delay-100'>
                <h1 className='font-bold m-2'>Back End</h1>
                <ul className='flex flex-wrap'>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Node.js</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Creating Express Apps</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Templating in JavaScript</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>APIs</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Introduction to Databases (SQL)</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>MongoDB</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Middlewares</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Authentication</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Handling Errors</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Deployment</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Microservices Backend</li>
                </ul>
            </div>
            <div className='md:mx-5 my-3 bg-violet-200 border-2 border-white p-5 shadow-lg rounded-xl duration-700 hover:-translate-y-5 hover:ease-in-out delay-100'>
                <h1 className='font-bold mb-5'>Extra Benifits</h1>
                <ul className='flex flex-wrap'>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>End With High Class 3 Projects</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Resume Building</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>LinkedIn Profile Building</li>
                    <li className='bg-violet-300 w-fit p-1 rounded-lg border-2 border-violet-300 m-1'>Interview Prep</li>
                </ul>
            </div>
        </div>
    )
}
