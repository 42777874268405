import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import LogoPixelPirates from "../assets/logo";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "./getUser";
import LottieAnimation from "./LottieAnimation";

export default function Certification() {
  const { certificationId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [downLoad, setDownload] = useState(false);

  const certificateRef = useRef();
  const scallingRef = useRef();

  useEffect(() => {
    const userData = getUser(certificationId, navigate);
    if (!userData) {
      navigate("/notFound");
    }
    setUser(userData);
  }, []);

  useEffect(() => {
    if (downLoad) {
      const certificateElement = certificateRef.current;
      html2canvas(certificateElement, {
        useCORS: true, // to enable CORS if using images
        scale: 2, // Increase the scale for better resolution
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        // Define custom dimensions
        const pdfWidth = 800; // Custom width in points
        const pdfHeight = 500; // Custom height in points

        const pdf = new jsPDF("landscape", "pt", [pdfWidth, pdfHeight]);

        // Calculate aspect ratio to fit the image within PDF dimensions
        const imgWidth = pdfWidth;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Center the image in the PDF
        const xOffset = (pdfWidth - imgWidth) / 2;
        const yOffset = (pdfHeight - imgHeight) / 2;

        // Add the image to the PDF
        pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

        // Save the PDF
        pdf.save(`Certificate_${certificationId}.pdf`);
      });
      setDownload(false);
    }
  }, [downLoad]);

  const handleDownload = async () => {
    setDownload(true);
    // Capture the element as a canvas
  };

  return (
    <div className=" w-screen h-screen flex flex-col justify-center items-center">
      <div className=" flex flex-col w-screen h-screen justify-center items-center bg-indigo-50">
        <div
          className={`w-screen justify-center ${
            !downLoad ? "scale-[0.45]" : "scale-100 translate-x-0"
          } sm:translate-x-0 sm:scale-100 flex items-center hidescrollbar`}
        >
          <div className=" border-2 w-fit">
            <div
              className="w-[800px] h-[500px] bg-white p-5 relative"
              ref={certificateRef}
            >
              <div className=" h-full flex flex-col justify-evenly bg-orange-50 p-5">
                <div className="">
                  <h1 className=" text-4xl font-extrabold">Certification</h1>
                  <h5 className="text-xl font-extrabold text-gray-600">
                    Of Complition
                  </h5>
                </div>

                <div className="">
                  <h5 className="text-4xl font-extrabold">
                    MERN Stack Development{" "}
                  </h5>

                  <h6>
                    Instructor{" "}
                    <span className="font-extrabold">Siddhant Kadam</span>
                  </h6>
                </div>
                <div className="border-l-4 border-fuchsia-700 py-1">
                  <div className=" pl-4 p-1">
                    <h5 className="text-lg font-extrabold text-gray-600">
                      This certificate is proudly presented to
                    </h5>
                    <h5 className=" text-2xl font-extrabold">{user?.name}</h5>
                  </div>
                </div>

                <div>
                  Date <span className=" font-extrabold">July 10 2024</span>
                </div>
              </div>

              <div className=" bg-fuchsia-700 w-20 h-1/2 absolute right-16 top-0">
                <div className=" w-full flex justify-center items-center absolute">
                  {/* <img src={LogoPixelPirates} alt="" /> */}
                  <div className=" transform rotate-90 pl-24">
                    <LogoPixelPirates />
                  </div>
                </div>
                <div className=" w-full h-full relative flex">
                  <div className="w-24 h-24 bg-orange-50 transform rotate-45 absolute -bottom-10 -left-2"></div>
                </div>
              </div>
              <div className=" w-fit h-24 text-gray-600 absolute bottom-5 right-5 p-5 flex flex-col items-end justify-end font-semibold">
                <p className=" text-[8px]  text-right">
                  Certificate Id: {certificationId}
                </p>
                <p className=" text-[8px]  text-right">
                  Certificate url: pixelPirates.tech/certificate/
                  {certificationId}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-screen text-xs px-5 md:w-1/2 md:p-0 md:pt-10">
          This is to certify that {user?.name} has successfully completed the
          MERN Stack Development course on May 28, 2023. Siddhant has
          demonstrated strong dedication and understanding of MongoDB,
          Express.js, React, and Node.js. Under the guidance of Siddhant Dhanaji
          Kadam, a distinguished Developer and Lead Instructor at PixelPirates,
          Siddhant completed all required coursework and assessments, showcasing
          a solid commitment to learning and professional growth in web
          development.
        </div>

        <div className=" p-5 bg-indigo-50 w-screen flex justify-center items-center">
          <button
            onClick={handleDownload}
            className="mt-4 px-4 py-2 bg-purple-600 text-white rounded text-xs md:text-lg self-center"
          >
            Download Certificate
          </button>
        </div>
      </div>
    </div>
  );
}
