import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landingpage from "./components/landingPage";
import UserMenu from "./components/userView/usermain";
import Tasks from "./components/userView/task";
import AdditionofTasks from "./components/teacherDashBoard/taskAddition";
import { useState } from "react";
import TaskQuestions from "./components/teacherDashBoard/taskQuestions";
import CompanyLinks from "./components/teacherDashBoard/companyLinks";
import NotFound from "./components/ErrorFiles/notfound";
import ExploreMenu from "./components/exploreMenu";
import Login from "./components/Authentication Pages/login";
import ProtectedRoutes from "./components/Authentication Pages/PtotectionRoute";
import CoursesForStudents from "./components/Student/EducationalCourses";
import Certification from "./pages/Certification";

function App() {
  const [clickedButton, setClickedButton] = useState("home");
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Landingpage
              setClickedButton={setClickedButton}
              clickedButton={clickedButton}
            />
          }
        />
        {/* <Route
          path="/usermenu"
          element={
            <UserMenu
              setClickedButton={setClickedButton}
              clickedButton={clickedButton}
            />
          }
        />
        <Route
          path="/usermenu/tasks"
          element={
            <Tasks
              setClickedButton={setClickedButton}
              clickedButton={clickedButton}
            />
          }
        />
        <Route
          path="/user/Student"
          element={
            <Login
              setClickedButton={setClickedButton}
              clickedButton={clickedButton}
            />
          }
        />
        <Route
          path="/teacherpage/addtask"
          element={
            <CoursesForStudents
              setClickedButton={setClickedButton}
              clickedButton={clickedButton}
            />
          }
        />
        <Route
          path="/tasks/:class/:task"
          element={
            <TaskQuestions
              setClickedButton={setClickedButton}
              clickedButton={clickedButton}
            />
          }
        />
        <Route
          path="/CompanyLinks"
          element={
            <CompanyLinks
              setClickedButton={setClickedButton}
              clickedButton={clickedButton}
            />
          }
        />
        <Route
          path="/exploreMenu"
          element={
            <ExploreMenu
              setClickedButton={setClickedButton}
              clickedButton={clickedButton}
            />
          }
        /> */}
        <Route
          path="/login"
          element={
            <Login
              setClickedButton={setClickedButton}
              clickedButton={clickedButton}
            />
          }
        />
        <Route
          path="/certificate/:certificationId"
          element={<Certification />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
