import { Snackbar } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import MuiAlert from "@mui/material/Alert";
export default function Login(props) {
  const [loginstate, setLoginstate] = useState(true);
  const navigate = useNavigate();
  const [errorShow, setErrorShow] = useState(false);
  const [loginData, setLogInData] = useState({
    username: "",
    password: "",
  });

  const [signUpData, setSignUpData] = useState({
    username: "",
    password: "",
    name: "",
  });

  const handleLogIn = (event) => {
    let { name, value } = event.target;
    setErrorShow(true);
    setLogInData((prev) => ({ ...prev, [name]: value }));
    // if (loginData.username === "siddh" && loginData.password === "password") {
    //   navigate(`/certificate/${loginData.username}`);
    // }
    // return async function () {
    //   console.log(loginData);
    //   try {
    //     const response = await fetch("http://localhost:500/login", {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //         credentials: "include", // Include session cookies
    //       },
    //       body: JSON.stringify(loginData),
    //     });

    //     if (!response.ok) {
    //       throw new Error(`HTTP error! Status: ${response.status}`);
    //     }

    //     const data = await response.json();

    //     if (data.status) {
    //       // Login successful, navigate or display success message
    //       console.log("Login successful");
    //       navigate("/exploreMenu"); // Assuming 'navigate' function is defined for navigation
    //     } else {
    //       // Login failed, display error message
    //       console.error("Login failed: " + data.message); // Assuming data.message contains error details
    //     }
    //   } catch (error) {
    //     console.error("Error logging in: " + error.message);
    //     // Display user-friendly error message to the user
    //   }
    // };
  };

  const handleGoogleSignup = () => {};

  const handleSignUp = (event) => {
    let { name, value } = event.target;
    setSignUpData((prev) => ({ ...prev, [name]: value }));
    return function () {
      console.log(signUpData);
      fetch("http://localhost:500/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          credentials: "included",
        },
        body: JSON.stringify(signUpData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("HTTPS error! Status");
          }
          return response.json();
        })
        .then((data) => {
          if (data.status) {
            navigate("/exploreMenu");
          }
        })
        .catch((error) => {
          console.log("Error msg " + error);
        });
    };
  };

  if (loginstate) {
    return (
      <div className="w-screen h-screen flex items-center  relative md:overflow-x-hidden md:overflow-y-hidden">
        {/* <div className="w-1/2 h-3/4 flex">
          <div className=" w-2/6 h-2/4 rounded-full bg-orange-700 -z-10 animate_log_back_3"></div>
          <div className="w-1/4 h-2/3 bg-yellow-600 -z-20 rounded-full animate_log_back"></div>
          <div className="w-3/12 h-2/4 rounded-full bg-violet-900 -z-10 animate_log_back_2"></div>
        </div> */}

        <div className="flex flex-col md:flex-row absolute py-10 pt-40 md:p-0 z-20 bg-white/20 backdrop-blur-3xl overflow-y-scroll overflow-x-hidden md:overflow-x-hidden md:overflow-y-hidden hidescrollbar  justify-center items-center h-screen w-screen">
          {/* <div className="md:w-1/2 md:p-20 m-5">
            <button
              onClick={() => navigate("/")}
              className="rounded-full bg-white my-5 p-3 px-4 flex items-center shadow-md"
            >
              <span class="material-symbols-outlined">arrow_back</span>
            </button>
            <h5 className="font-bold pt-10">For Everyone who want to</h5>
            <h1 className=" protest-strike-regular text-6xl font-bold">
              Learn,Teach and Edify
            </h1>
            <p className="font-bold py-10 text-justify pb-10">
              For aspiring educators and eager learners, our platform is more
              than a digital space; it's a canvas to create, share, and inspire.
              Unleash your potential, whether you're here to impart wisdom or
              soak in the wealth of knowledge curated by a diverse community.
            </p>
            <p>pixelPirates</p>
          </div> */}
          <div className="md:w-1/3 md:p-10">
            <div className="border-2 border-white p-20 bg-violet-200 rounded-md flex text-center flex-col shadow-xl shadow-violet-300 justify-center items-center">
              <h1 className="protest-strike-regular text-black text-2xl w-full mb-5">
                Login To Your Account
              </h1>
              <p className="cursor-pointer text-sm">
                {/* Don't Have Account{" "} */}
                {/* <span
                  className="text-violet-700"
                  onClick={() => setLoginstate(!loginstate)}
                >
                  Sign up
                </span> */}
              </p>
              <input
                onChange={handleLogIn}
                name="username"
                type="text"
                placeholder="email"
                className="my-2 p-2 focus:outline-none w-full shadow-lg"
              />
              <input
                onChange={handleLogIn}
                name="password"
                type="password"
                placeholder="********"
                className="my-2 p-2 focus:outline-none w-full shadow-lg"
              />
              <button
                onClick={(event) => handleLogIn(event)}
                className="bg-violet-400 p-2 rounded-md font-bold w-full shadow-lg my-2"
              >
                Login
              </button>
              {/* <p className="font-bold py-5 text-xs">Or Log In With</p> */}
              {/* <button
                onClick={() =>
                  (window.location.href = "http://localhost:500/auth/google")
                }
                className="w-fit flex justify-around font-bold items-center border-2 bg-white rounded-md m-1 p-2"
              >
                <i className="fa-brands fa-google mx-3"></i>
                Google Login
              </button> */}
            </div>
          </div>
        </div>
        <Snackbar
          open={errorShow}
          autoHideDuration={2000}
          onClose={() => setErrorShow(false)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => setErrorShow(false)}
            severity="error"
            style={{ backgroundColor: "#ff9900", color: "black" }}
          >
            Access denied
          </MuiAlert>
        </Snackbar>
      </div>
    );
  } else {
    return (
      <div className="w-screen h-screen flex justify-center items-center relative">
        {/* <div className="w-1/4 h-2/3 bg-violet-500 -z-10  rounded-full"></div>
        <div className="w-3/12 h-2/4 rounded-full bg-violet-900 -z-10"></div>
        <div className=" w-2/6 h-3/4 "></div> */}
        <div className=" flex-col md:flex-row absolute z-20 bg-white/20 backdrop-blur-3xl flex justify-center items-center h-screen w-screen">
          {/* <div className="md:w-1/2 md:px-20 md:pb-10">
            <button
              onClick={() => navigate("/")}
              className="rounded-full bg-white my-10 p-3 px-4 flex items-center shadow-md"
            >
              <span class="material-symbols-outlined">arrow_back</span>
            </button>
            <h5 className="font-bold">For Everyone who want to</h5>
            <h1 className=" protest-strike-regular text-6xl font-bold">
              Learn,Teach and Edify
            </h1>
            <p className="font-bold py-10 text-justify">
              For aspiring educators and eager learners, our platform is more
              than a digital space; it's a canvas to create, share, and inspire.
              Unleash your potential, whether you're here to impart wisdom or
              soak in the wealth of knowledge curated by a diverse community.
            </p>
            <p className="font-bold">pixelPirates</p>
          </div> */}
          <aside className="md:w-1/2 p-10">
            <div className="border-2 border-white p-20 bg-violet-200 rounded-md flex text-center flex-col shadow-xl shadow-violet-300 justify-center items-center">
              {/* <h1>Pixel Pirates</h1> */}
              <h1 className="protest-strike-regular text-black text-2xl w-full">
                Create An Account
              </h1>
              <p className="cursor-pointer text-sm">
                Have Account{" "}
                <span
                  className="text-violet-700"
                  onClick={() => setLoginstate(!loginstate)}
                >
                  Log In
                </span>
              </p>
              <input
                onChange={handleSignUp}
                name="name"
                type="text"
                placeholder="Name"
                className="my-2 p-2 focus:outline-none w-full shadow-lg"
              />
              <input
                onChange={handleSignUp}
                name="username"
                type="email"
                placeholder="email"
                className="my-2 p-2 focus:outline-none w-full shadow-lg"
              />
              <input
                onChange={handleSignUp}
                name="password"
                type="password"
                placeholder="********"
                className="my-2 p-2 focus:outline-none w-full shadow-lg"
              />
              <button
                onClick={(event) => handleSignUp(event)()}
                className="bg-violet-400 p-2 rounded-md font-bold w-full"
              >
                Sing up
              </button>
              <p className="font-bold py-5 text-xs">Or Sign up With</p>
              <button
                onClick={handleGoogleSignup}
                className="w-fit flex justify-around font-bold items-center border-2 bg-white rounded-md m-1 p-2"
              >
                <i className="fa-brands fa-google mx-3"></i>
                Google Sign up
              </button>
            </div>
          </aside>
        </div>
      </div>
    );
  }
}
