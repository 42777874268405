import React from 'react';



export default function BecameTeacher(props) {
    return(
        <div id='whyUS' className='md:p-10 p-5 py-10 bg-slate-200'>
            <h1 className='font-bold text-xl md:text-2xl'><span className='text-yellow-500 bg-gradient-to-r from-violet-500 to-violet-800 p-2 rounded-lg text-3xl px-4'>♦️</span> Become Teacher</h1>
            <div className='flex flex-col-reverse sm:flex-row-reverse justify-center items-center md:p-10 mt-5'>
                <div className='font-bold text-white flex flex-col items-center justify-center md:w-1/2 my-5'>
                    <div className='bg-yellow-500 shadow-2xl shadow-purple-800 rounded-t-3xl flex justify-center items-baseline mx-10 max-w-80'>
                        <div className='bg-white m-2 rounded-t-3xl p-2 flex justify-center items-center '>
                            <img className='h-100 w-100 bg-cover bg-center' src="/landingPage/teacher.jpg" alt="coding person" />
                        </div>
                    </div>
                </div>
                <div className='md:w-1/2'>
                    <h1 className='font-bold text-2xl'>Why Teach at pixelPirates?</h1>
                    <p className='my-5'>At pixelPirates, we believe in the power of sharing knowledge and shaping the future of coding. If you're passionate about your field and want to inspire the next generation of coders, joining our team of instructors is the perfect opportunity.</p>
                    <div className='grid grid-cols-2'>
                        <div className='m-1 bg-violet-200 p-5 rounded-md shadow-md border-2 border-white hover:-translate-x-1 hover:-translate-y-1 delay-100 hover:ease-in-out duration-300'>
                            <h1 className='font-bold'>Requirement</h1>
                            <p className='text-justify'>Strong coding background, excellent communication, and a passion for teaching are key qualities we value at pixelPirates.</p>
                        </div>
                        <div className='m-1 bg-violet-200 p-5 rounded-md shadow-md border-2 border-white hover:translate-x-1 hover:-translate-y-1 delay-100 hover:ease-in-out duration-300'>
                            <h1 className='font-bold'>Group Compition</h1>
                            <p className='text-justify'>Students are divided in the groups and groups compete with other groups.</p>
                        </div>
                        <div className='m-1 bg-violet-200 p-5 rounded-md shadow-md border-2 border-white hover:-translate-x-1 hover:translate-y-1 delay-100 hover:ease-in-out duration-300'>
                            <h1 className='font-bold'>Flexible Schedule</h1>
                            <p className='text-justify'>We understand the importance of work-life balance. As an instructor at pixelPirates, you have the flexibility to choose your teaching schedule, ensuring convenience and comfort.</p>
                        </div>
                        <div className='m-1 bg-violet-200 p-5 rounded-md shadow-md border-2 border-white hover:translate-x-1 hover:translate-y-1 delay-100 hover:ease-in-out duration-300'>
                            <h1 className='font-bold'>Join Us</h1>
                            <p className='text-justify'>Join pixelPirates, shape aspiring coders' growth. Make coding education exciting! For inquiries, contact instructor@pixelpirates.in. We welcome you to our team!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}